import React from "react"
import cx from "classnames"
import { Avatar, Skeleton } from "antd"
import { IComment } from "../../interfaces/comment"
import { getDifference } from "../../utils/date"
import dayjs from "dayjs"
import relativeTime from "dayjs/plugin/relativeTime"
dayjs.extend(relativeTime)

interface Props extends IComment {
  className?: string
  isLoading?: boolean
}

const Comment = ({
  content,
  name,
  image,
  createdAt,
  id,
  className,
  isLoading,
}: Props) => {
  const nDate = dayjs(createdAt).subtract(3, 'hour').format()
  return (
    <Skeleton loading={isLoading} active={isLoading} paragraph={isLoading}>
      <aside>
        <li key={id} className={cx(className, "list-none flex flex-col")}>
          <div className="flex flex-row items-center mb-5">
            <div className="mr-4">
              <Avatar size={42} src={image} />
            </div>
            <div className="flex flex-col">
              <h4 className="text-maastrichtBlue mb-[3px] leading-[0.8] font-bold text-base">
                {name}
              </h4>
              <p className="text-base font-thin text-jet mb-0">
                {getDifference(nDate)}
              </p>
            </div>
          </div>
          <div>
            <p
              className="text-jet mb-0 text-base font-light text-left pb-0"
              dangerouslySetInnerHTML={{ __html: content }}
            />
          </div>
        </li>
      </aside>
    </Skeleton>
  )
}

export default Comment
