import React from "react"
import _get from "lodash/get"
import { IComment, ICommentForm } from "../interfaces/comment"
import {
  getCommentsByPostId as getCommentsByPostIdService,
  createComment,
} from "../services/comments"
import {
  formatCommentData,
  formatdCommentPaylod,
} from "../utils/formatCommentData"
import { IWPErrorResponse } from "../interfaces/wpResponse"

interface IArticleCommentsContext {
  getCommentsByPostId: (id: number) => Promise<void>
  comments: IComment[]
  saveComment: (
    data: ICommentForm
  ) => Promise<{ message: string; err?: boolean; success?: boolean }>
}

const ArticleCommentsContext = React.createContext(
  {} as IArticleCommentsContext
)

interface Props {
  children: React.ReactNode
}

export const ArticleCommentsProvider = ({ children }: Props): JSX.Element => {
  const [comments, setComments] = React.useState([] as IComment[])

  const getCommentsByPostId = async (id: number): Promise<void> => {
    const data = await getCommentsByPostIdService({ id })
    const formatedComments = formatCommentData({ comments: data })
    setComments(formatedComments)
  }

  const saveComment = async (
    data: ICommentForm
  ): Promise<{ message: string }> => {
    const formatdCommentData = formatdCommentPaylod(data)
    const onSuccess = (): { message: string; success?: boolean } => ({
      success: true,
      message:
        "Seu comentário foi enviado com sucesso e está aguardando aprovação!",
    })
    const onError = (
      err: IWPErrorResponse
    ): { message: string; err?: boolean } => ({
      message: _get(
        err,
        "message",
        "Não foi possível realizar seu comentário, tente novamente mais tarde"
      ),
      err: true,
    })
    return await createComment({
      comment: formatdCommentData,
      onSuccess,
      onError,
    })
  }

  return (
    <ArticleCommentsContext.Provider
      value={{
        comments,
        getCommentsByPostId,
        saveComment,
      }}
    >
      {children}
    </ArticleCommentsContext.Provider>
  )
}
export const useArticleComments = (): IArticleCommentsContext => {
  const context = React.useContext(ArticleCommentsContext)

  if (!context) {
    throw new Error(
      "useArticleComments must be used within a ArticleCommentsProvider!"
    )
  }

  return context
}

export default useArticleComments
