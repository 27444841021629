import React from "react"
import { Alert } from "antd"
import { AlertProps } from "antd/lib/alert"
import "./styles.less"
const Snackbar = ({ ...props }: AlertProps): JSX.Element => (
  <div className="snack_bar_wrapper">
    <Alert {...props} />
  </div>
)

export default Snackbar
