import React from "react"
import { Helmet } from "react-helmet"
import { IArticleFormatedImage } from "../../interfaces/article"
import { getCurrentUrl } from "../../utils/url"

interface Props {
  children: React.ReactNode
  title: string
  description: string
  featuredImage: IArticleFormatedImage
}
const Layout = ({
  children,
  title,
  description,
  featuredImage,
}: Props): JSX.Element => {
  const currentUrl = getCurrentUrl()
  const imgArticle = featuredImage.srcSet.split(',')[0].split(' ')[0];
  return (
    <>
      <Helmet>
        <title>{title + " | eNe Soluções"}</title>
        <meta charSet="utf-8" />
        <meta http-equiv="X-UA-Compatible" content="IE=edge" />
        <meta name="csrf_token" content="" />
        <meta property="type" content="website" />
        <meta property="url" content={currentUrl} />
        <meta
          name="viewport"
          content="width=device-width, initial-scale=1, shrink-to-fit=no"
        />
        <meta name="msapplication-TileColor" content="#ffffff" />
        <meta name="msapplication-TileImage" content="/ms-icon-144x144.png" />
        <meta name="theme-color" content="#ffffff" />
        <meta name="_token" content="" />
        <meta name="robots" content="noodp" />
        <meta property="title" content={title} />
        <meta property="quote" content={description} />
        <meta name="description" content={description} />
        <meta property="image" content={imgArticle} />
        <meta property="og:locale" content="pt-BR" />
        <meta property="og:type" content="website" />
        <meta property="og:title" content={title} />
        <meta property="og:quote" content={description} />
        <meta property="og:image" content={imgArticle} />
        <meta content="image/*" property="og:image:type" />
        <meta property="og:url" content={currentUrl} />
        <meta property="og:site_name" content="CampersTribe" />
        <meta property="og:description" content={description} />
      </Helmet>
      <main className="font-barlow">{children}</main>
    </>
  )
}

export default Layout
