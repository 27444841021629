import { Form, Input } from "antd"
import { useForm } from "antd/lib/form/Form"
import React from "react"
import useArticleComments, {
  ArticleCommentsProvider
} from "../../hooks/useArticleComments"
import { validateOnlyLetters } from "../../utils/util"
import Snackbar from "../SnackBar"
import "./styles.less"

const initialValues = {
  "name": '',
  "email": '',
  "comment": ''
}

const DESC_RULES = [
  { required: true },

  {
    pattern: /\S/,
    message: "O campo deve conter um texto",
  },
]

const VALIDATE_MESSAGES = {
  required: "O campo é obrigatório.",
  types: {
    email: "Insira um e-mail válido.",
  },
}

const NAME_RULES = [
  { required: true, message: "Insira o nome e sobrenome", },
]

interface Props {
  currentPost: number
}

const CommentForm = ({ currentPost }: Props): JSX.Element => {
  const { TextArea } = Input;
  const [form] = useForm()
  const { saveComment } = useArticleComments()
  const [hasError, setHasError] = React.useState<boolean | undefined>()
  const [sucess, setSucess] = React.useState<boolean | undefined>()
  const [message, setMessage] = React.useState("")
  const handleSubmit = async (): Promise<void> => {
    const values = form.getFieldsValue()
    const res = await saveComment({ ...values, postId: currentPost })
    setHasError(res.err)
    setSucess(res.success)
    setMessage(res.message)
    form.resetFields()
  }

  function validateName(e: any) {
    let completeName = e.target.value;
    if (completeName.split(' ').length < 2 || completeName.split(' ')[1] === '') {
      form.setFieldsValue({ 'name': '' })
      form.validateFields(['name'])
    }
  }

  return (
    <section className="py-16">
      <h4 className="text-2xl text-maastrichtBlue font-bold mb-0">
        Escreva um comentário:
      </h4>
      {(sucess || hasError) && (
        <Snackbar
          className="sticky inset-x-0 top-0 transition duration-500 ease-in-out"
          message={message}
          type={sucess && !hasError ? "success" : "error"}
          showIcon
        />
      )}
      <div className="comment-form">
        <Form
          form={form}
          onFinish={handleSubmit}
          validateMessages={VALIDATE_MESSAGES}
          initialValues={initialValues}
        >
          <Form.Item name="name" rules={NAME_RULES}>
            <Input className="capitalize" id="name" placeholder="Nome" onBlur={validateName} onChange={(e) => {
              validateOnlyLetters(e, form, "name")
            }} />
          </Form.Item>

          <Form.Item name="email" rules={[{ type: "email" }, { required: true }]}>
            <Input id="email" placeholder="E-mail" />
          </Form.Item>

          <Form.Item name="comment" rules={DESC_RULES}>
            <TextArea
              id="comment"
              showCount
              placeholder="Comentário"
            />
          </Form.Item>

          <div className="w-full flex items-center justify-center pt-8">
            <button
              className="rounded-full text-white font-bold bg-persianGreen py-3 px-8"
              type="submit"
            >
              Enviar
            </button>
          </div>
        </Form>
      </div>
    </section>
  )
}

const _WithProvider = ({ ...props }): JSX.Element => (
  <ArticleCommentsProvider>
    <CommentForm {...props} />
  </ArticleCommentsProvider>
)

export default _WithProvider
