import React from "react"
import cx from "classnames"
import { AiOutlineLink, AiFillLinkedin } from "react-icons/ai"
import {
  FacebookShareButton,
  FacebookIcon,
  TwitterShareButton,
  TwitterIcon,
  LinkedinShareButton,
  LinkedinIcon,
} from "react-share"
import { getCurrentUrl } from "../../utils/url"
import * as styles from "./styles.module.css"
import { copyToClipboard } from "../../utils/clipboard"
import { Tooltip } from "antd"

interface Props {
  postTitle: string
}
const TOOLTIP_INITIAL_MESSAGE = "Link copiado!"
const TOOLTIP_ERROR_MESSAGE = "Não foi possível copiar o link."

const ShareButtons = ({ postTitle }: Props): JSX.Element => {
  const currentUrl = getCurrentUrl()

  const [showTooltip, setShowTooltip] = React.useState<boolean>()
  const [tolltipMessage, setTollTipMessage] = React.useState<string>(
    TOOLTIP_INITIAL_MESSAGE
  )

  const tooltipColor =
    tolltipMessage === TOOLTIP_INITIAL_MESSAGE ? "#0e2437" : "red"

  const iconsClassNames = cx("rounded-full mr-2", styles.icons)
  const copyIconClassName = cx(
    "w-[28px] h-[28px] rounded-full bg-persianGreen flex justify-center items-center"
  )

  const onCopySuccess = () => {
    setShowTooltip(true)
    setTimeout(() => {
      setShowTooltip(false)
      setTollTipMessage(TOOLTIP_INITIAL_MESSAGE)
    }, 500)
  }

  const onCopyError = () => {
    setTollTipMessage(TOOLTIP_ERROR_MESSAGE)
    onCopySuccess()
  }

  const handleCopyText = () =>
    copyToClipboard(currentUrl, onCopySuccess, onCopyError)
  return (
    <div className="flex flex-row items-start ">
      <FacebookShareButton url={currentUrl} quote={postTitle}>
        <FacebookIcon size={28} className={iconsClassNames} />
      </FacebookShareButton>
      <LinkedinShareButton url={currentUrl} title={postTitle}>
        <LinkedinIcon size={28} className={iconsClassNames} />
      </LinkedinShareButton>
      <TwitterShareButton url={currentUrl} title={postTitle}>
        <TwitterIcon size={28} className={iconsClassNames} />
      </TwitterShareButton>

      <button className={copyIconClassName} onClick={handleCopyText}>
        <Tooltip
          title={tolltipMessage}
          visible={showTooltip}
          color={tooltipColor}
        >
          <AiOutlineLink size={20} className={styles.copyIcon} />
        </Tooltip>
      </button>
    </div>
  )
}

export default ShareButtons
