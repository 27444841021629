import _cloneDeep from "lodash/cloneDeep"
import _get from "lodash/get"
import React from "react"

import { graphql } from "gatsby"

import ArticleBody from "../../components/ArticleBody"
import ArticleComments from "../../components/ArticleComments"
import ArticleFooter from "../../components/ArticleFooter"
import ArticleHeader from "../../components/ArticleHeader"
import Footer from "../../components/Footer"
import Layout from "../../components/Layout"
import Menu from "../../components/Menu"
import {
  ArticleCommentsProvider
} from "../../hooks/useArticleComments"
import { IQueryArticle } from "../../interfaces/article"
import { formatQueryArticleData } from "../../utils/formatArticleData"
import "./styles.less"

interface Props {
  data: { wpPost: IQueryArticle }
}

const Article = ({ data: { wpPost } }: Props) => {
  const tags = _cloneDeep(wpPost.tags.nodes);
  const currentArticle = formatQueryArticleData(wpPost)
  const featuredImage = _get(currentArticle, ["featuredImage"])
  const category = _get(currentArticle, ["categories", "0"])
  const realTags = [category, ...tags.map(e => {
    return {
      text: `${e.name = e.name.toUpperCase()}`,
      icon: ""
    }
  })]
  const title = _get(currentArticle, "title")
  const content = _get(currentArticle, "content")
  const author = _get(currentArticle, "author")
  const currentPostId = _get(currentArticle, "databaseId")
  const commentsCount = _get(currentArticle, "commentCount") === null ? 0 : _get(currentArticle, "commentCount")

  return (
    <Layout title={title} description="" featuredImage={featuredImage}>
      <div className="m-auto main-container">
        <Menu />
      </div>
      <main className="bg-white pt-8">
        <div className="m-auto main-container fix-badges-post">
          <div className="w-full lg:w-10/12 xl:w-8/12 m-auto">
            <section className="m-auto main-container">
              <ArticleHeader {...currentArticle} tag={category} />

              <ArticleBody featuredImage={featuredImage} content={content} />
            </section>
          </div>
          <div className="xx:main-container xx:m-auto">
            <div className="xx:w-10/12 xx:m-auto">
              <ArticleFooter
                author={author}
                tags={realTags}
                postTitle={title}
              />
            </div>
            <div className="xx:w-8/12 xx:m-auto">
              <ArticleComments currentPost={currentPostId} />
            </div>
          </div>
        </div>
      </main>
      <Footer />
    </Layout>
  )
}

export const query = graphql`
  query ArticlePageQuery($id: String) {
    wpPost(id: { eq: $id }) {
      id
      slug
      title
      uri
      dateGmt
      databaseId
      content
      featuredImage {
        node {
          altText
          caption
          srcSet
          uri
        }
      }
      author {
        node {
          avatar {
            url
          }
          firstName
          lastName
        }
      }
      tags {
        nodes {
          name
          id
        }
      }
      categories {
        nodes {
          id
          name
        }
      }
    }
  }
`
const _WithContext = ({ ...props }: Props): JSX.Element => (
  <ArticleCommentsProvider>
    <Article {...props} />
  </ArticleCommentsProvider>
)

export default _WithContext

