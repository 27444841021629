import React from "react"
import cx from "classnames"
import _size from "lodash/size"
import _isEmpty from "lodash/isEmpty"
import _map from "lodash/map"

import Comment from "./Comment"

import * as styles from "./styles.module.css"
import CommentForm from "./CommentForm"
import useArticleComments, {
  ArticleCommentsProvider,
} from "../../hooks/useArticleComments"

interface Props {
  currentPost: number
}

const ArticleComments = ({ currentPost }: Props): JSX.Element => {
  const { getCommentsByPostId, comments } = useArticleComments()
  const [loading, setLoading] = React.useState(true)
  const getCommentWrapperClassName = (index: number) =>
    cx("pb-8 md:pb-9 lg:pb-8 mb-8", styles.commentWrapper, styles.borderBottom)

  const fetchComments = async (): Promise<void> => {
    setLoading(true)
    await getCommentsByPostId(currentPost).finally(() => {
      setTimeout(() => setLoading(false), 300)
    })
  }

  React.useEffect(() => {
    fetchComments()
  }, [])

  return (
    <section
      className={cx(styles.commentsContainer, "mt-16 md:mt-[47px] lg:mt-16")}
    >
      {!_isEmpty(comments) && (
        <>
          <h3 className="text-xl pb-[1.625rem] md:pb-6 text-maastrichtBlue font-bold md:text-2xl">
            Comentários:
          </h3>
          {_map(comments, (commnent, index) => (
            <Comment
              isLoading={loading}
              {...commnent}
              key={commnent.id}
              className={getCommentWrapperClassName(index)}
            />
          ))}
        </>
      )}
      <div className="w-full lg:w-9/12 lg:m-auto">
        <section className="m-auto main-container">
          <CommentForm currentPost={currentPost} />
        </section>
      </div>
    </section>
  )
}


export default ArticleComments
