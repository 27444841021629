import _get from "lodash/get";

export const getCurrentUrl = (location?: string, path?: string): string => {
    if (typeof window === "undefined") return "https://enesolucoes.com.br/blog"
    return _get(location, path ? [path] : "href")
}


export function validateOnlyLetters(text: any, form: any, target: any) {
    let textOnlyLetters = text.target.value;
    textOnlyLetters = textOnlyLetters.replace(/[^a-zA-ZÀ-ÿ'˜ ]/g, '');

    form.setFieldsValue({ [target]: textOnlyLetters });
}

