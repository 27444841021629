import cx from "classnames"
import React from "react"

import { IArticleFormatedImage } from "../../interfaces/article"
import WpImage from "../WpImage"
import * as styles from "./styles.module.css"
interface Props {
  featuredImage: IArticleFormatedImage
  content: string
}
const ArticleBody = ({ content, featuredImage }: Props): JSX.Element => {
  const bannerClassName =
    "w-[338px] h-[154px] object-cover mb-6 md:w-full md:h-auto md:min-h-[328px] lg:min-h-[436px]"

  const contentClassName = cx("pb-6", styles.content)

  return (
    <section className="pt-6">
      <WpImage
        {...featuredImage}
        figureClassName={bannerClassName}
        imgClassName="w-full h-full object-cover rounded-2xl"
      />
      <article
        className={contentClassName}
        dangerouslySetInnerHTML={{ __html: content }}
      />
    </section>
  )
}

export default ArticleBody
