// extracted by mini-css-extract-plugin
export var content = "styles-module--content--kJj37";
export var inline = "styles-module--inline--BmVZ5";
export var button = "styles-module--button--irL58";
export var fauxButton = "styles-module--faux-button--2J3HH";
export var wpBlockButton__link = "styles-module--wp-block-button__link--1A0ai";
export var wpBlockFile__button = "styles-module--wp-block-file__button--9nqUm";
export var wpBlockButton = "styles-module--wp-block-button--286aO";
export var wpBlockFile = "styles-module--wp-block-file--3oiX5";
export var enableSearchModal = "styles-module--enable-search-modal--yfrc5";
export var headerTitlesWrapper = "styles-module--header-titles-wrapper--HPHTy";
export var singular = "styles-module--singular--VIdoR";
export var wpBlockGallery = "styles-module--wp-block-gallery--2q6wf";
export var alignleft = "styles-module--alignleft--3y_Hn";
export var alignright = "styles-module--alignright--1o8i6";
export var headingSize1 = "styles-module--heading-size-1--3J8AH";
export var headingSize2 = "styles-module--heading-size-2--1zaNe";
export var headingSize3 = "styles-module--heading-size-3--uQd0L";
export var headingSize4 = "styles-module--heading-size-4--2yheb";
export var headingSize5 = "styles-module--heading-size-5--3yxli";
export var headingSize6 = "styles-module--heading-size-6--1RKT4";
export var entryTitle = "styles-module--entry-title--3HGFq";
export var primaryMenu = "styles-module--primary-menu--3vofn";
export var authorCard = "styles-module--authorCard--21cxV";