import React from "react"
import { IArticleFormatedImage } from "../../interfaces/article"

interface Props extends IArticleFormatedImage {
  imgClassName?: string
  figureClassName?: string
}

const WpImage = ({
  altText,
  caption,
  srcSet,
  uri,
  imgClassName,
  figureClassName,
}: Props): JSX.Element => (
  <figure className={figureClassName}>
    <img
      src={uri}
      srcSet={srcSet}
      className={imgClassName}
      alt={altText || caption}
      title={caption}
    />
  </figure>
)

export default WpImage
