import axiosWpInstance from "../axios"
import {
  ICreateCommentRequest,
  IResponseComments,
} from "../../interfaces/comment"
import { IWPErrorResponse } from "../../interfaces/wpResponse"

const COMMENT_BASE_URL = "/comments"

interface ICreateComment {
  comment: ICreateCommentRequest
  onSuccess: () => { message: string }
  onError: (err: IWPErrorResponse) => { message: string }
}

export const getCommentsByPostId = async ({
  id,
}: {
  id: number
}): Promise<IResponseComments[]> =>
  await axiosWpInstance
    .get<IResponseComments[]>(`${COMMENT_BASE_URL}?post=${id}`)
    .then(({ data }) => data)
    .catch((_) => {
      console.error("Error to fetch wp comments")
      return [] as IResponseComments[]
    })

export const createComment = async ({
  comment,
  onError,
  onSuccess,
}: ICreateComment): Promise<{
  message: string
}> =>
  await axiosWpInstance
    .post(COMMENT_BASE_URL, {
      ...comment,
    })
    .then(onSuccess)
    .catch(onError)
