import _get from "lodash/get"
import _map from "lodash/map"
import _head from "lodash/head"
import {
  IComment,
  ICommentForm,
  ICreateCommentRequest,
  IQueryComment,
  IResponseComments,
} from "../interfaces/comment"
import { getNowDateWp } from "./date"

const getCommentAuthorImg = ({ images }: { [key: string]: string }): string => {
  const AVATAR_URL = _head(_map(images, (item) => item)) || ""
  return AVATAR_URL
}

const formatData = (comment: IResponseComments | IQueryComment): IComment => ({
  content: _get(comment, ["content", "rendered"]),
  createdAt: _get(comment, "date_gmt"),
  id: _get(comment, "id"),
  image: getCommentAuthorImg({ images: _get(comment, ["author_avatar_urls"]) }),
  name: _get(comment, "author_name"),
})
export const formatCommentData = ({
  comments,
}: {
  comments: IResponseComments[]
}): IComment[] => _map(comments, formatData)

export const formatdCommentPaylod = (
  comment: ICommentForm
): ICreateCommentRequest => ({
  author_email: _get(comment, "email"),
  author_name: _get(comment, "name"),
  content: _get(comment, "comment"),
  date: getNowDateWp(),
  date_gmt: getNowDateWp(),
  post: _get(comment, "postId"),
})
