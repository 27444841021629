import React from "react"
import _map from "lodash/map"
import { IArticle, ITag } from "../../interfaces/article"
import AuthorInfo from "../AuthorInfo"
import ShareButtons from "../ShareButtons"
import ArticleBadges from "../ArticleBadges"
import { formatDateToArticleCard } from "../../utils/date"

interface Props
  extends Omit<IArticle, "content" | "featuredImage" | "id" | "tag"> {
  tag: ITag
}
const ArticleHeader = ({
  tag,
  slug,
  title,
  author,
  createdAt,
  description,
}: Props): JSX.Element => {
  return(
    <section className="flex flex-col items-start">
    <div className="w-full">
      <div className="pb-6 ">
        <ArticleBadges tags={[tag]} />
      </div>
      <h1
        title={title}
        className="text-maastrichtBlue text-2.375xl mb-5 md:mb-6 font-bold font-barlow leading-[1.21] md:text-5.5xl md:leading[1.1]"
      >
        {title}
      </h1>
      {description && (
        <p className="font-barlow font-light text-base pb-4 mb-0 ">
          {description}
        </p>
      )}
    </div>
    <div className="flex flex-col w-full md:flex-row md:items-center  md:justify-between">
      <div className="flex flex-col md:flex-row md:items-center">
        {/* <div className="pb-[15px] md:mr-6 md:pb-0">
          <AuthorInfo {...author} />
        </div> */}
        <p className="pb-4 mb-0 text-sm md:pb-0">
          {formatDateToArticleCard(createdAt)}
        </p>
      </div>
      <div className="justify-self-end">
        <ShareButtons postTitle={title} />
      </div>
    </div>
  </section>
  )
}

export default ArticleHeader
