import React from "react"
import _map from "lodash/map"
import cx from "classnames"
import { ITag } from "../../interfaces/article"
import Badges from "../Badges"

interface Props {
  tags: ITag[]
  onlyFirst?: boolean
}

const ArticleBadges = ({ tags }: Props) => (
  <>
    {_map(
      tags,
      ({ text, icon }: { text: string; icon: string }, index: number) =>
      {
        const regexIsThereEmoji = /(?:[\u2700-\u27bf]|(?:\ud83c[\udde6-\uddff]){2}|[\ud800-\udbff][\udc00-\udfff])[\ufe0e\ufe0f]?(?:[\u0300-\u036f\ufe20-\ufe23\u20d0-\u20f0]|\ud83c[\udffb-\udfff])?(?:\u200d(?:[^\ud800-\udfff]|(?:\ud83c[\udde6-\uddff]){2}|[\ud800-\udbff][\udc00-\udfff])[\ufe0e\ufe0f]?(?:[\u0300-\u036f\ufe20-\ufe23\u20d0-\u20f0]|\ud83c[\udffb-\udfff])?)*/
        const regexResults = regexIsThereEmoji.test(icon)
        return (
          <div className="w-[fit-content] mb-2" key={text}>
            {regexResults &&
              <Badges
                textWhite={index === 0}
                textColor={cx({
                  "text-maastrichtBlue border-persianGreen": index !== 0,
                })}
                bgColor={cx({ "bg-persianGreen border-persianGreen": index === 0 })}
                text={text}
                icon={icon}
                index={index}
              />
            }
            {!regexResults &&
              <Badges
                textWhite={index === 0}
                textColor={cx({
                  "text-maastrichtBlue border-persianGreen": index !== 0,
                })}
                bgColor={cx({ "bg-persianGreen border-persianGreen": index === 0 })}
                text={`${icon} ${text}`}
                index={index}
              /> 
            }
          </div>
        )
      }
    )}
  </>
)

export default ArticleBadges
