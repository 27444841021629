import React from "react"
import cx from "classnames"
import _size from "lodash/size"
import AuthorInfo from "../AuthorInfo"
import ArticleBadges from "../ArticleBadges"
import ShareButtons from "../ShareButtons"
import Clap from "../Clap"
import Balloon from "../Balloon"
import { IAuthor, ITag } from "../../interfaces/article"
import useArticleComments, {
  ArticleCommentsProvider,
} from "../../hooks/useArticleComments"
import * as styles from "./styles.module.css"

interface Props {
  author: IAuthor
  tags: ITag[]
  postTitle: string
  claps?: number
}

const ArticleFooter = ({
  claps,
  postTitle,
  author,
  tags,
}: Props): JSX.Element => {
  const { comments } = useArticleComments()
  const iconClassName = "w-[20px] h-[20px] mr-3 cursor-pointer"
  const counterClassName = "text-sonicSilver text-xl font-bold mb-0"
  const iconWrapperClassName = "flex flex-row items-center"
  const authorCardWrapper = cx(
    styles.authorCard,
    "md:flex md:flex-row md:justify-between"
  )
  const bagesWrapper = "py-6 md:pt-[8px] md:pb-0 flex flex-row justify-start flex-wrap"
  return (
    <section className={authorCardWrapper}>
      <div className="flex flex-col">
        {/* <div className=" md:pb-0">
          <AuthorInfo {...author} />
        </div> */}
        <div className={bagesWrapper}>
          <ArticleBadges tags={tags} />
        </div>
      </div>
      <div className="flex flex-col md:pt-[8px]">
        <ShareButtons postTitle={postTitle} />
        <aside className="flex flex-row items-start pt-6 md:pt-7">
          {/* {claps && (
            <div className={cx(iconWrapperClassName, "mr-6")}>
              <Clap className={iconClassName} />
              <p className={counterClassName}>{claps}</p>
            </div>
          )} */}
          <div className={iconWrapperClassName}>
            <Balloon className={iconClassName} />
            <p className={counterClassName}>{comments.length}</p>
          </div>
        </aside>
      </div>
    </section>
  )
}

export default ArticleFooter
