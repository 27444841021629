import React from "react"
import cx from "classnames"
import { default as balloonImg } from "../../images/balloon.png"

interface Props {
  className?: string
}
const Balloon = ({ className }: Props) => (
  <img src={balloonImg} className={cx("object-cover", className)} />
)

export default Balloon
